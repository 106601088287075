export const Permissions = {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update',
    SHOW_ACTIVE_TOGGLE: 'show-active-toggle',
    DOWNLOAD_DATA: 'download-data',
    UPLOAD_DATA: 'upload-data',
    SEARCH_FILTERS: 'search-filters',
    WRITE: 'write',
    DELETE: 'delete',
    VIEW: 'view',
    PRINT: 'print',
    SHARE: 'share'
  }