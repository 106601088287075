export const USER_DATA = 'USER_DATA';
export const CATEGORY = 'CATEGORY';
export const SUB_CATEGORY = 'SUB_CATEGORY';
export const INVENTORY_DATA = 'INVENTORY_DATA';
export const SUPPLIER = 'SUPPLIER';
export const MANUFACTURER = 'MANUFACTURER';
export const PURCHASE_REQUEST = 'PURCHASE_REQUEST';
export const ROLE_DATA = 'ROLE_DATA';
export const BOOKING_SLOT_DATA = 'BOOKING_SLOT_DATA';
export const ADD_CLINIC = 'ADD_CLINIC';
export const CLINIC_DETAILS = 'CLINIC_DETAILS';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const VALIDATE_RESET_PW_TOKEN = 'VALIDATE_RESET_PW_TOKEN';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const MASTER_DATA = 'MASTER_DATA';
export const EMAIL_TEMPLATE_DATA = 'EMAIL_TEMPLATE_DATA';
export const CLINIC_TABLE = 'CLINIC_TABLE';
export const TEAM_LIST = 'TEAM_LIST';
export const STAFF_DATA = 'STAFF_DATA';
export const PATIENT_DATA = 'PATIENT_DATA';
export const PATIENT_LIST = 'PATIENT_LIST';
export const ADD_CLINIC_ADMIN = 'ADD_CLINIC_ADMIN';
export const SERVICE = 'SERVICE';
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const APPOINTMENT_ZOOM_SESSION = 'APPOINTMENT_ZOOM_SESSION';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const TOGGLE_TABLE_SWITCH = 'TOGGLE_TABLE_SWITCH';
export const GET_PRACTICES_DATA = 'GET_PRACTICES_DATA';
export const PRACTICE_LOCATION_DATA = 'PRACTICE_LOCATION_DATA';
export const GET_LOCATION_DATA = 'GET_LOCATION_DATA';
export const SAVE_LOCATION_DATA = 'SAVE_LOCATION_DATA';
export const GET_PRACTICE_DATA_SETTING = 'GET_PRACTICE_DATA_SETTING';
export const UPDATE_PRACTICE_DATA_SETTING = 'UPDATE_PRACTICE_DATA_SETTING';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const GET_PRACTICES_LOCATION = 'GET_PRACTICES_LOCATION_DATA';
export const GET_PRACTICES_SERVICES = 'GET_PRACTICES_SERVICES_DATA';
export const GET_EDUCATION_CONTENT = 'GET_EDUCATION_CONTENT_DATA';
export const PRACTICE_ENUM_MASTERS_DATA = 'PRACTICE_ENUM_MASTERS_DATA';
export const GET_PRACTICE = 'GET_PRACTICE';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_PRACTITIONERS = 'GET_PRACTITIONERS';
export const GET_FAX_CONTACT_DATA = 'GET_FAX_CONTACT_DATA';
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const GET_INVOICE = 'GET_INVOICE';
export const INVOICE_PAYMENT = 'INVOICE_PAYMENT';
export const GET_INVOICE_PAYMENT = 'GET_INVOICE_PAYMENT';
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const GET_MEDICINE_DATA = 'GET_MEDICINE_DATA';
export const GET_PATIENT_ORDER_DATA = 'GET_PATIENT_ORDER_DATA';
export const UPDATE_PATIENT_ORDER_DATA = 'UPDATE_PATIENT_ORDER_DATA';
export const GET_PHARMACY_ORDER_DATA = 'GET_PHARMACY_ORDER_DATA';
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const GET_PHARMACY_FAX_DATA = 'GET_PHARMACY_FAX_DATA';
export const SEND_PHARMACY_FAX_DATA = 'SEND_PHARMACY_FAX_DATA';
export const GET_CHAT_TEMPLATE = 'GET_CHAT_TEMPLATE';
export const PATIENT_PRESCRIPTION_DATA = 'PATIENT_PRESCRIPTION_DATA';
export const GET_CHAT_CHANNEL_HISTORY = 'GET_CHAT_CHANNEL_HISTORY';
export const CREATE_CHAT_CHANNEL_HISTORY = 'CREATE_CHAT_CHANNEL_HISTORY';
export const UPDATE_CHAT_CHANNEL_HISTORY = 'UPDATE_CHAT_CHANNEL_HISTORY';
export const SEND_USER_INVITE = 'SEND_USER_INVITE';
export const JOIN_APPOINTMENT_SESSION = 'JOIN_APPOINTMENT_SESSION';
export const GET_FAX_HISTORY = 'GET_FAX_HISTORY';
export const CREATE_DEVICE_TOKEN = 'CREATE_DEVICE_TOKEN';
export const GET_MEDICINE_TEMPLATE = 'GET_MEDICINE_TEMPLATE';
export const CLOSED_CHAT_CHANNEL = ' CLOSED_CHAT_CHANNEL';
export const THIRD_PARTY_SETTING = 'THIRD_PARTY_SETTING';
export const GET_SHIPMENT_LABEL = 'GET_SHIPMENT_LABEL';
export const GET_PRACTICE_STATS = 'GET_PRACTICE_STATS';
export const GET_PRACTICE_INVOICE_STATS = 'GET_PRACTICE_INVOICE_STATS';
export const GET_PRACTICE_TOTAL_INVOICE_STATS =
  'GET_PRACTICE_TOTAL_INVOICE_STATS';
export const GET_SHIPMENT_LABEL_UPS = 'GET_SHIPMENT_LABEL_UPS';
export const ALLERGIES_LIST = 'ALLERGIES_LIST';
export const ALLERGY_DATA = 'ALLERGY_DATA';
export const SAVE_ALLERGIES_DATA = 'SAVE_ALLERGIES_DATA';
export const SAVE_CHAT_DATA = 'SAVE_CHAT_DATA';
export const SEND_CHAT_DATA = 'SEND_CHAT_DATA';
export const USER_CHAT_LIST = 'USER_CHAT_LIST';
export const CHAT_MESSAGES = 'CHAT_MESSAGES';
export const DELETE_CHAT_MESSAGES_DATA = 'DELETE_CHAT_MESSAGES_DATA';
export const DIAGNOSIS_LIST = 'DIAGNOSIS_LIST';
export const DIAGNOSIS_DATA = 'DIAGNOSIS_DATA';
export const SAVE_DIAGNOSIS_DATA = 'SAVE_DIAGNOSIS_DATA';
export const DIAGNOSIS_STATUS = 'DIAGNOSIS_STATUS';
export const INSURANCE_LIST = 'INSURANCE_LIST';
export const SAVE_INSURANCE_DATA = 'SAVE_INSURANCE_DATA';
export const SAVE_FAMILY_HISTORY = 'SAVE_FAMILY_HISTORY';
export const UPDATE_FAMILY_HISTORY = 'UPDATE_FAMILY_HISTORY';
export const GET_FAMILY_HISTORY = 'GET_FAMILY_HISTORY';
export const VITALS_LIST = 'VITALS_LIST';
export const VITALS_DATA = 'VITALS_DATA';
export const SAVE_VITALS_DATA = 'SAVE_VITALS_DATA';
export const SAVE_SOCIAL_HISTORY = 'SAVE_SOCIAL_HISTORY';
export const UPDATE_SOCIAL_HISTORY = 'UPDATE_SOCIAL_HISTORY';
export const GET_SOCIAL_HISTORY = 'GET_SOCIAL_HISTORY ';
export const SAVE_MEDICAL_HISTORY = 'SAVE_MEDICAL_HISTORY';
export const UPDATE_MEDICAL_HISTORY = 'UPDATE_MEDICAL_HISTORY';
export const GET_MEDICAL_HISTORY = 'GET_MEDICAL_HISTORY';
export const DOCUMENT_LIST = 'DOCUMENT_LIST';
export const DOCUMENT_DATA = 'DOCUMENT_DATA'
export const SAVE_DOCUMENTS_DATA = 'SAVE_DOCUMENTS_DATA';
export const SAVE_EMERGENCY_CONTACT = 'SAVE_EMERGENCY_CONTACT';
export const GET_EMERGENCY_CONTACT = 'GET_EMERGENCY_CONTACT';
export const UPDATE_EMERGENCY_CONTACT = 'UPDATE_EMERGENCY_CONTACT';
export const SAVE_MEDICATION_DATA = 'SAVE_MEDICATION_DATA';
export const MEDICATION_LIST = 'MEDICATION_LIST';
export const MEDICATION_DATA = 'MEDICATION_DATA';
export const TABLE_DROPDOWN_DATA = 'TABLE_DROPDOWN_DATA';
export const APPOINTMENT_STATUS = 'APPOINTMENT_STATUS';
export const APPOINTMENT_TYPE = 'APPOINTMENT_TYPE';
export const WEEKLY_PATIENTS = 'WEEKLY_PATIENTS';
export const MONTHLY_PATIENTS = 'MONTHLY_PATIENTS';
export const ALL_PATIENTS = 'ALL_PATIENTS';
export const GET_ALL_PATIENT_HISTORY = 'GET_ALL_PATIENT_HISTORY';
export const SAVE_ALL_PATIENT_HISTORY = 'SAVE_ALL_PATIENT_HISTORY';
export const SAVE_LABS_RADIOLOGY_DATA = 'SAVE_LABS_RADIOLOGY_DATA';
export const DELETE_LABS_RADIOLOGY_DATA = 'DELETE_LABS_RADIOLOGY_DATA';
export const LABS_RADIOLOGY_LIST='LABS_RADIOLOGY_LIST';
export const LABS_RADIOLOGY_DATA='LABS_RADIOLOGY_DATA';
export const SEND_TO_LAB = 'SEND_TO_LAB';
export const ENCOUNTERS_LIST = 'ENCOUNTERS_LIST';
export const ENCOUNTER_DATA = 'ENCOUNTER_DATA';
export const ENCOUNTERS_FORM_LIST ="ENCOUNTERS_FORM_LIST";
export const SAVE_PATIENT_ENCOUNTER = 'SAVE_PATIENT_ENCOUNTER';
export const ENCOUNTER_DATA_VIEW = 'ENCOUNTER_DATA_VIEW';
export const FORM_LIBRARY_DATA = 'FORM_LIBRARY_DATA';


export const SAVE_MASTER_DATA = 'SAVE_MASTER_DATA';
export const DELETE_MASTER_DATA = "DELETE_MASTER_DATA";

export const SAVE_PATIENT_ENCOUNTER_BILLING = 'SAVE_PATIENT_ENCOUNTER_BILLING';
export const GET_PATIENT_ENCOUNTER_BILLING = 'GET_PATIENT_ENCOUNTER_BILLING';
export const ENCOUNTER_BILLING_DATA = 'ENCOUNTER_BILLING_DATA';



export const TREATMENT_PLAN = 'TREATMENT_PLAN';
export const ICD_PROBLEM = 'ICD_PROBLEM';
export const PROBLEM_BEHAVIOR = 'PROBLEM_BEHAVIOR';
export const BEHAVIOR_GOAL = 'BEHAVIOR_GOAL';
export const GOAL_OBJECTIVE = 'GOAL_OBJECTIVE';
export const OBJECTIVE_INTERVENTION = 'OBJECTIVE_INTERVENTION';
export const SAVE_TREATMENT_PLAN_DATA = 'SAVE_TREATMENT_PLAN_DATA';
export const DELETE_TREATMENT_PLAN_DATA = 'DELETE_TREATMENT_PLAN_DATA';
export const SAVE_STAFF_DATA = 'SAVE_STAFF_DATA';

export const STAFF_LIST = 'STAFF_LIST';

export const LAB_TEST_LIST = 'LAB_TEST_LIST';
export const SAVE_LAB_TEST_DATA ='SAVE_LAB_TEST_DATA';
export const DELETE_LAB_TEST_DATA ='DELETE_LAB_TEST_DATA';

export const TESTING_LAB_LIST = 'TESTING_LAB_LIST';
export const SAVE_TESTING_LAB_DATA = 'SAVE_TESTING_LAB_DATA';
export const DELETE_TESTING_LAB_DATA = 'DELETE_TESTING_LAB_DATA';

export const LAB_REPORT = 'LAB_REPORT';

export const PRACTICE_SETTING = 'PRACTICE_SETTING';

export const UPDATE_APPOINTMENT= 'UPDATE_APPOINTMENT';

export const SAVE_PRACTICE_LOCATION= 'SAVE_PRACTICE_LOCATION';
export const DELETE_PRACTICE_LOCATION= 'DELETE_PRACTICE_LOCATION';

export const SAVE_PRACTICE_DATA_SETTING = 'SAVE_PRACTICE_DATA_SETTING';

export const ROLE_LIST = 'ROLE_LIST';
export const SAVE_ROLE_DATA = 'SAVE_ROLE_DATA';
export const DELETE_ROLE_DATA = 'DELETE_ROLE_DATA';
export const ROLE_AND_MODULES_DATA = 'ROLE_AND_MODULES_DATA';

export const ROLE_AND_PERMISSIONS = 'ROLE_AND_PERMISSIONS';
export const CREATE_PAYMENT ='CREATE_PAYMENT';
export const CREATE_PAYMENT_INTENT = 'CREATE_PAYMENT_INTENT';

export const SAVE_MEDICATION_SCHEDULE = 'SAVE_MEDICATION_SCHEDULE';
export const MEDICATION_ITEM_LIST = 'MEDICATION_ITEM_LIST';
export const CREATE_MAR_LOG = 'CREATE_MAR_LOG';
export const GET_MEDICINE_STATUS = 'GET_MEDICINE_STATUS';
export const MAR_FILTERS = 'MAR_FILTERS';
export const STAFF_LOCATION_DATA= 'STAFF_LOCATION_DATA';
export const SAVE_STAFF_LOCATION_DATA ='SAVE_STAFF_LOCATION_DATA';
export const DELETE_STAFF_LOCATION_DATA='DELETE_STAFF_LOCATION_DATA';

export const GET_STAFF_BOOKING_DATA = 'GET_STAFF_BOOKING_DATA';
export const SAVE_BOOKING_SETTING='SAVE_BOOKING_SETTING';
export const SAVE_CALENDAR_SCHEDULE='SAVE_CALENDAR_SCHEDULE';

export const SAVE_USER='SAVE_USER';

export const MAR_STATUS='MAR_STATUS';
export const VALIDATE_ZOOM_SESSION_INVITE = 'VALIDATE_ZOOM_SESSION_INVITE';
export const INVITE_USER_ZOOM_SESSION = 'INVITE_USER_ZOOM_SESSION';
export const MD_TOOLBOX_SYNC = 'MD_TOOLBOX_SYNC';
export const DELETE_MD_TOOLBOX_CONFIG = 'DELETE_MD_TOOLBOX_CONFIG';
export const MD_TOOLBOX_CONFIG = 'MD_TOOLBOX_CONFIG';
export const GET_MD_TOOLBOX_CONFIG = 'GET_MD_TOOLBOX_CONFIG';
export const EPRESCREBE_MEDICATION_LIST ='EPRESCREBE_MEDICATION_LIST';


export const CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY';
export const GET_OFFICE_ALLY_CONFIG = 'GET_OFFICE_ALLY_CONFIG';
export const OFFICE_ALLY_CONFIG = 'OFFICE_ALLY_CONFIG';
export const DELETE_OFFICE_ALLY_CONFIG = 'DELETE_OFFICE_ALLY_CONFIG';
export const GET_OFFICE_ALLY_PAYER_LIST = 'GET_OFFICE_ALLY_PAYER_LIST';
export const GET_FREQUENCY_OF_DOSE_ADJUSTMENT = 'GET_FREQUENCY_OF_DOSE_ADJUSTMENT';


export const ADD_APPOINTMENT_NOTES = 'ADD_APPOINTMENT_NOTES';
export const GET_APPOINTMENT_NOTES = 'GET_APPOINTMENT_NOTES';

export const SAVE_TREATMENT_PLAN_TEMPLATE_DATA = 'SAVE_TREATMENT_PLAN_TEMPLATE_DATA';
export const GET_TREATMENT_PLAN_TEMPLATE = 'GET_TREATMENT_PLAN_TEMPLATE';







