export const BASE_URL = process.env.REACT_APP_API_PATH;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_API_PATH;
export const UPLOAD_URL = `${BASE_URL}upload`;
export const DOWNLOAD_URL = `${BASE_URL}download`;
export const REQUEST_METHOD = {
  get: 'read',
  post: 'create',
  update: 'update',
  delete: 'delete',
  postDebounce:'createWithDebounce',
};
export const API_URL = {
  login: 'auth/login',
  logout: 'auth/logout',
  users: 'users',
  staff:"staff",
  staffLocation: "staff-location",
  setStaffPrimaryLocation: "staff-location/set-primary-location",
  refreshToken: 'auth/refresh-tokens',
  signup: 'auth/register',
  forgotPassword: 'auth/forgot-password',
  authenticate: 'auth/validate-token',
  practices: 'practice',
  resetPassword: 'auth/reset-password',
  changePassword: 'auth/change-password',
  getEnumMasterType: 'enumMasterType',
  getMasters: 'global-type',
  saveMasters: 'global-type',
  emailTemplates: 'email-templates',
  assistant: 'assistant',
  practitioner: 'practitioner',
  patient: 'patient',
  clinicAdmin: 'clinic-admin',
  services: 'service',
  practiceLocation: 'practice-location',
  getPractice: 'practice-location',
  getPatients: 'patient',
  practiceLocationSchedule: 'practice-location-schedule',
  educationContent: 'education-content',
  getPatientNotes: 'patient-note',
  updatePatientNote: 'patient-note',
  faxContact: 'fax-contact',
  bookingService: 'booking/service',
  bookingPractice: 'booking/practice',
  booking: 'booking',
  getFormList: 'form',
  saveForm: 'form',
  product: 'product',
  invoice: 'invoice',
  invoiceAggregate: 'invoice/aggregate',
  invoicePayment: 'invoice-payment',
  medicine: 'medicine',
  patientOrder: 'patient-order',
  pharmacyOrder: 'pharmacy-order',
  sharedFormList: 'patient-form',
  getPatientFormById: 'patient-form',
  savePatientForm: 'patient-form-submission',
  saveOnlyPatientForm: 'patient-form-submission',
  getPatientForm: 'patient-form-submission',
  chatTemplate: 'chat-template',
  downloadInvoicePDF: 'pdf/invoice',
  downloadPatientFormPDF: 'pdf/patientForm',
  patientPrescription: 'patient-prescription',
  patientActivity: 'patient-activity',
  patientActivityHistory: 'patient-activity/history',
  sendUserInvite: 'appointment/sendUserInvite',
  getEducationContent: 'education-content',
  chatChannelHistory: 'chat-channel-history',
  faxHistory: 'fax-history',
  sendPharmacyFax: 'fax-history',
  downloadPharmacyOrderPDF: 'pdf/pharmacy-order',
  shareNoteTemplate: 'patient-form/share-note-template',
  userDevice: 'user-device',
  patientPrescriptionTemplate: 'patient-prescription-template',
  initiatePayment: 'invoice-payment/initiate-payment',
  markClose: 'chat-channel-history/mark-close',
  thirdPartySetting: 'practice-setting',
  patientFile: 'patient-file',
  generateLabel: 'patient-order/generate-label',
  practiceStats: 'practice/stats',
  invoiceStats: 'invoice-stats',
  invoiceStatsTotal: 'invoice-stats/total',
  notification: 'notification',
  generateUpsLabel: 'patient-order/generate-label-ups',
  updatePatientOrder: 'patient-order',
  appointmentStats: 'appointment-stats',
  validateResetPWToken: 'auth/validate-reset-password-token',
  allergies: 'allergies',
  chats: 'chats',
  diagnosis: 'diagnosis',
  diagnosisProblem: 'diagnosisProblem',
  diagnosisIcd: 'diagnosisIcd',
  insurance: 'insurance',
  familyHistory: 'familyHistory',
  vitals: 'vitals',
  socialHistory: 'socialHistory',
  medicalHistory: 'medicalHistory',
  document: 'patient-document',
  emergencyContact:'emergency-contact',
  appointment:'appointment',
  providersAppointment:'appointment/providersAppointment',
  medication:'patient-medication',
  brandDrug:'brand-name-drug',
  genericDrug:'generic-drug',
  markBillingSelf:'insurance/markBillingSelf',
  sharePatientMedication:'patient-medication/share',
  sharePatientLabRadiology:'labs-radiology/share',
  downloadPatientMedicationPDF: 'pdf/patientMedication',
  allPatientHistory: 'all-patient-history',
  updateMasters: 'global-type/update',
  laboratoryTest : 'laboratory-test',
  testingLab : 'testing-lab',
  labsRadiology:'labs-radiology',
  hl7: 'hl7',
  patientEncounter:'patient-encounter',
  procedureCode:'procedureCode',
  diagnosisSnometCT:'diagnosisSnomed',

  globalTypeCategory: 'global-type-category',
  getAllMasters: 'global-type/All',
  patientEncounterBilling:'patient-encounter-billing',

  treatmentPlan: 'treatment-plan',
  icdProblem: 'icd-problem',
  problemBehavior: 'problem-behavior',
  behaviorGoal: 'behavior-goal',
  goalObjective: 'goal-objective',
  objectiveIntervention: 'objective-intervention',
  labsRadiologyResult: '/labs-radiology-results',
  role: 'role',
  labReport: '/lab-report',
  createAppleCalanderEvent:'appointment/createAppleCalanderEvent',
  practiceSetting: 'practice-settings',
  module: 'module',
  roleAndModules: 'roleAndModules',
  roleAndPermissions: 'roleAndPermissions',
  sendBirthdayMail:'send-birthday-mail',
  formLibrary: 'form-library',
  stripePayment:'stripe-payment',
  medicationSchedule: 'medication-schedule',
  medicationItems:'patient-medication-items',
  medicationItemMARLog:'patient-medication-item-mar-log',
  downloadMedicationMARLogPDF: 'pdf/patient-medication-item-mar-log',
  staffBookingSetting: 'staff-booking-setting',
  calendarSchedule: 'calendar-schedule',
  downloadPatientEncounterPDF: 'pdf/patientEncounter',
  downloadPatientAllMedicationPDF: 'pdf/patientAllMedication',
  createZoomSession: 'appointment/crete-zoom-session',
  validateZoomSessionInvite:'zoom-session/validate-invite',
  inviteUserToZoomSession:'zoom-session/add-invite',
  downloadAllMedicationsMARLogPDF: 'pdf/patient-medication-items-mar-log',
  mdToolboxSync: 'md-toolbox/patient-upload',
  mdToolboxConfig: 'md-toolbox-config',
  ///////////////////////office-ally/////////////////
  eligibilityCheck: 'office-ally/eligibilityCheck',
  eligibilityCheckHistory: 'office-ally/eligibilityCheck/history',
  officeAllyConfig: 'office-ally-config',
  officeAllyPayerList: 'office-ally/payerList',
  ///////////////////////////////////////////////////////
  analyticsAndReporting: 'analytics-and-reporting',
  appointmentNotes:'appointment-notes',

  treatmentPlanTemplate: 'treatment-plan-template',

};

export const AUTH_MODULE = {
  globalTypeCategory: 'GLOBAL_TYPE_CATEGORY',
  globalType: 'GLOBAL_TYPE',
  practice: 'PRACTICE',
  patient: 'patient',

  patientSummary: 'summary',
  demographics: 'demographic',
  paientAppointments: 'appointment',
  medications: 'medication',
  emar: 'PATIENTMEDICATION',
  erx: 'PATIENTMEDICATION',
  patientEncounters: 'patient Encounters',
  allergies: 'ALLERGIES',
  diagnosis: 'Diagnosis',
  vitals: 'Vitals',
  patientHistory: 'History',
  insurance: 'Insurance',
  emergencyContact: 'Emergency Contact',
  patientLabsRadiology: 'Lab/Radiology',
  patientDocument: 'Documents',
  treatmentPlan: 'Treatment Plan',
  patientForm:'Forms',
  
  appointments: 'Scheduling',
  encounters: 'Encounter',
  labsRadiology: 'LabRadiology',
  medicalBilling: 'Medical Billing',
  analyticsAndReport: 'Analytics And Reporting',
  messages: 'Messages',
  alerts: 'Alerts',
  tasks: 'TASKS',
  fax: 'Fax',
  formSettings: 'Form Settings',
  staff: 'Staff',
  systemSettings: 'System Settings',


  patientFormSubmission:'PATIENT_FORM_SUBMISSION',
  patientMedicationHistory: 'PATIENTMEDICATIONHISTORY',
  diagnosisicd: 'DIAGNOSIS_ICD',
  diagnosisproblem: 'DIAGNOSIS_PROBLEM',
  familyHistory: 'FAMILY_HISTORY',
  socialHistory: 'SOCIAL_HISTORY',
  medicalHistory: 'MEDICAL_HISTORY',
  genericDrug: 'GENERIC_DRUG',
  brandNameDrug: 'BRAND_NAME_DRUG',
  hl7: 'HL7',
  form:'FORM',
  downloadPatientFormPdf:'DOWNLOAD_PATIENT_FORM_PDF',
  faxContact:'Fax_CONTACT',
  faxHisotry:'FAX_HISTORY',
  allPatientHistory:'ALL_PATIENT_HISTORY',
  laboratoryTest:'LABORATORY_TEST',
  testingLab:'TESTING_LAB',
  emailTemplate: 'EMAILTEMPLATE',
  encounterBilling: 'ENCOUNTERBILLING',
  diagnosisSnomed: 'DIAGNOSISSNOMED',
  procedureCode: "PROCEDURE_CODE", 
  encountersBilling: 'ENCOUNTERSBILLING',
  icdProblem: 'ICDPROBLEM',
  problemBehavior: 'PROBLEMbEHAVIOR',
  behaviorGoal: 'BEHAVIORGOAL',
  goalObjective: 'GOALOBJECTIVE',
  objectiveIntervention: 'OBJECTIVEINTERVENTION',
  labReport: 'LABREPORT'
};

export const MODULE = {
  globalTypeCategory: 'GLOBAL_TYPE_CATEGORY',
  globalType: 'GLOBAL_TYPE',
  practice: 'PRACTICE',
  patient: 'patient',

  patientSummary: 'patientSummary',
  demographics: 'patientDemographic',
  paientAppointments: 'appointment',
  medications: 'patientMedication',
  emar: 'PATIENTMEDICATION',
  erx: 'PATIENTMEDICATION',
  patientEncounters: 'patientEncounters',
  allergies: 'patientAllergies',
  diagnosis: 'patientDiagnosis',
  vitals: 'patientVitals',
  patientHistory: 'patientHistory',
  insurance: 'patientInsurance',
  emergencyContact: 'patientEmergencyContact',
  patientLabsRadiology: 'patientLabRadiology',
  patientDocument: 'patientDocuments',
  treatmentPlan: 'patientTreatmentPlan',
  patientForm:'patient_form',
  
  appointments: 'scheduling',
  encounters: 'encounters',
  labsRadiology: 'labs_radiology',
  medicalBilling: 'medicalBilling',
  analyticsAndReport: 'analytics_and_reporting',
  messages: 'messages',
  alerts: 'alerts',
  tasks: 'tasks',
  faxContact: 'fax_contact',
  formSettings: 'form',
  staff: 'staff',
  systemSettings: 'systemSettings',
  fax: 'fax',
  medicalHistory: 'medical_history',


  patientFormSubmission:'PATIENT_FORM_SUBMISSION',
  patientMedicationHistory: 'PATIENTMEDICATIONHISTORY',
  diagnosisicd: 'DIAGNOSIS_ICD',
  diagnosisproblem: 'DIAGNOSIS_PROBLEM',
  familyHistory: 'FAMILY_HISTORY',
  socialHistory: 'SOCIAL_HISTORY',
  genericDrug: 'GENERIC_DRUG',
  brandNameDrug: 'BRAND_NAME_DRUG',
  hl7: 'HL7',
  form:'FORM',
  downloadPatientFormPdf:'DOWNLOAD_PATIENT_FORM_PDF',
  faxContact:'Fax_CONTACT',
  faxHisotry:'FAX_HISTORY',
  allPatientHistory:'ALL_PATIENT_HISTORY',
  laboratoryTest:'LABORATORY_TEST',
  testingLab:'TESTING_LAB',
  emailTemplate: 'EMAILTEMPLATE',
  encounterBilling: 'ENCOUNTERBILLING',
  diagnosisSnomed: 'DIAGNOSISSNOMED',
  procedureCode: "PROCEDURE_CODE", 
  encountersBilling: 'ENCOUNTERSBILLING',
  icdProblem: 'ICDPROBLEM',
  problemBehavior: 'PROBLEMbEHAVIOR',
  behaviorGoal: 'BEHAVIORGOAL',
  goalObjective: 'GOALOBJECTIVE',
  objectiveIntervention: 'OBJECTIVEINTERVENTION',
  labReport: 'LABREPORT',
  checkEligibility: 'eligibility_check_history',
  ////////////////PAIENT MODULE//////////////////////
  patientPortalMessages: 'patientPortalMessages',
  patientPortalAppointment: 'patientPortalappointment',
  patientPortalSharedForms: 'patient_form',
  patientPortalMedication: 'patientportalmedication',
  patientPortalAllergyData: 'patientPortalAllergies',
  patientPortalVitals: 'vitals',
  patientPortalLabRadiology: 'patientPortallabsRadiology',
  patientPortalLabRadiologyResult: 'patientPortallabsReport',
  // patientViewMedication,
  // patientLabRadiologyResultData:'labreport' ,
  // patientLabRadologyRequestData,
  ///////////////////////////////////////////////////
};

export const AUTH_ACTION = {
  create: 'CREATE',
  update: 'UPDATE',
  read: 'READ',
  share:'SHARE',
};


export const OPENTOK_API_KEY = process.env.REACT_APP_OPENTOK_API_KEY;
export const REACT_APP_COLLECTJS_API_KEY =
  process.env.REACT_APP_COLLECTJS_PUBLIC_KEY;

export const ENCRYPT_REQUEST_KEY = process.env.REACT_APP_P_11?.replace(/\\n/g, '\n');
export const DECRYPT_RESPONSE_KEY = process.env.REACT_APP_P_22?.replace(/\\n/g, '\n');
export const ENVIRONMENT = 'development';
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
