/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import FilterComponents from 'src/components/FilterComponents';
import Container from 'src/components/Container';
import Table from 'src/components/Table';
import { getFullName, practitionerFilterParser } from 'src/lib/utils';
import { API_URL } from 'src/api/constants';

import { dateFormats } from 'src/lib/constants';

import TableTextRendrer from 'src/components/TableTextRendrer';

const Claims = () => {
  const response = { results: []};

  const FilterCollectionHeader = useMemo(
    () =>
      FilterComponents({
        leftComponents: [
          {
            type: 'search',
            filterProps: {
              placeholder: 'Search patient',
            },
            name: 'searchText',
          },
        ],
        rightComponents: [
          {
            type: 'wiredSelect',
            filterProps: {
              name: 'practitionerFilter',
              url: API_URL.staff,
              label: 'Practitioner',
              labelAccessor: [
                'title.name',
                'firstName',
                'middleName',
                'lastName',
              ],
              params: { isActive: true, limit: 300 },
              valueAccessor: 'id',
              placeholder: 'Filter by Practitioner',
              size: 'small',
              style: { maxWidth: '220px' },
              cache: false,
              clearData: true,
              isAllOptionNeeded: true,
              defaultValue: 'ALL',
            },
            name: 'practitionerId',
            parser: practitionerFilterParser,
          },
        ],
      }),
    []
  );

  const columns = [
    {
        label: 'Patient',
        type: 'text',
        dataKey: 'patientId',
        sort: true,
        render: ({ data }) => (
          <TableTextRendrer style={{ maxWidth: '8rem' }}>
            {getFullName(data.patient || {})}
          </TableTextRendrer>
        ),
    },
    {
        label: 'Prescriber',
        type: 'text',
        dataKey: 'providerId',
        sort: true,
        maxWidth: '10rem',
        render: ({ data }) => (
            <TableTextRendrer style={{ maxWidth: '8rem' }}>
            {getFullName(data?.assignedTo || {})}
            </TableTextRendrer>
        ),
    },
    {
        label: 'Payer',
        type: 'text',
        dataKey: 'payer',
        sort: true,
        maxWidth: '10rem',
        render: ({ data }) => (
            <TableTextRendrer style={{ maxWidth: '8rem' }}>
            {getFullName(data?.assignedTo || {})}
            </TableTextRendrer>
        ),
    },
    {
        label: 'Transaction Date',
        type: 'date',
        dataKey: 'transactionDate',
        maxWidth: '10rem',
        sort: true,
        format: dateFormats.MMMDDYYYYHHMMSS,
    },
    {
        label: 'Amount',
        type: 'text',
        dataKey: 'amount',
        sort: true,
        maxWidth: '10rem',
    },
    {
        label: 'Claim Status',
        type: 'text',
        dataKey: 'claimStatus',
        sort: true,
        maxWidth: '10rem',
    },
    {
        label: 'Transaction Status',
        type: 'text',
        dataKey: 'transactionStatus',
        sort: true,
        maxWidth: '10rem',
    },
  ]
  
  return (
    <>
      <Container
        // loading={ loading }
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Table
          headerComponent={
            <div>
              <FilterCollectionHeader
                // onFilterChange={handleFilters}
                // filters={filters}
              />
            </div>
          }
          data={response?.results || []}
          totalCount={response?.totalResults}
          columns={columns}
          pagination
          // rowsPerPage={rowsPerPage}
          // page={page}
          // handlePageChange={handlePageChange}
          // loading={loading}
          // sort={sort}
          // handleSort={handleSort}
          wrapperStyle={{ boxShadow: 'none', borderRadius: 0 }}
          timezone
            //   actionButtons={(rowData) => moreActions(rowData)}
        />
      </Container>
    </>
  );
};

export default Claims;